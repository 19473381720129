import React from 'react';

import styles from './Keyword.module.scss';

const Keyword: React.FC = ({ children }) => (
  <div className={styles.keyword}>
    {children}
  </div>
);

export default Keyword;
