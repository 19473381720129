/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';

import styles from './Button.module.scss';

const Button: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  ...props
}) => (
  <button type="button" className={styles.button} {...props}>
    {children}
  </button>
);

export default Button;
